<template>
    <div class="text-center my-auto">
        <div :class="isMinus == true ? 'text-danger' : 'text-success'">
            <i class="fas font-weight-light mr-2" :class="isMinus == true ? 'fa-arrow-down' : 'fa-arrow-up'"></i>
            {{ percentage + '%' }}
        </div>
        <div class="font-bold">{{ total }}</div>
        <div class="uppercase">{{ title }}</div>
        <div class="font-bold">{{ totalItem }}</div>
    </div>
</template>

<script>
export default {
    props:{
        percentage:{
            type:Number,
            default:0
        },
        total:{
            type:Number,
            default:0
        },
        totalItem:{
            type:Number,
            default:0
        },
        title:{
            type:String,
            default:""
        },
        isMinus:{
            type:Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped></style>