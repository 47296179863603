<template>
  <vx-card>
    <div class="px-10">
      <!-- header -->
      <div class="w-full mb-6 flex flex-col gap-6">
        <div class="w-full flex items-center gap-6">
          <div
            class="w-1/3 text-5xl text-black font-semibold"
            style="flex-shrink: 0"
          >
            SO STAGES DASHBOARD
          </div>
          <div class="w-full flex flex-col gap-6">
            <div class="flex items-center gap-6">
              <div class="w-1/2 flex flex-col gap-2">
                <span>Operating Unit</span>
                <multiselect
                  class="selectExample"
                  v-model="selectedTerritories"
                  :options="optionTerritories"
                  :multiple="false"
                  :allow-empty="false"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                >
                </multiselect>
              </div>
              <div class="w-1/2 flex flex-col gap-2">
                <span>Territory</span>
                <multiselect
                  class="selectExample"
                  v-model="selectedTerritories"
                  :options="optionTerritories"
                  :multiple="false"
                  :allow-empty="false"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                >
                </multiselect>
              </div>
            </div>
            <div class="flex items-center gap-6">
              <div class="w-1/2 flex flex-col gap-2">
                <span>Principal / Divisi</span>
                <multiselect
                  class="selectExample"
                  v-model="selectedTerritories"
                  :options="optionTerritories"
                  :multiple="false"
                  :allow-empty="false"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                >
                </multiselect>
              </div>
              <div class="w-1/2 flex flex-col gap-2">
                <span>Distribution Channel</span>
                <multiselect
                  class="selectExample"
                  v-model="selectedTerritories"
                  :options="optionTerritories"
                  :multiple="false"
                  :allow-empty="false"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                >
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center gap-6">
          <div class="w-1/3 flex flex-col gap-2" style="flex-shrink: 0">
            <span>Region</span>
            <multiselect
              class="selectExample"
              v-model="selectedTerritories"
              :options="optionTerritories"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              :custom-label="customLabel"
            >
            </multiselect>
          </div>
          <div class="w-1/3 flex flex-col gap-2">
            <span>Customer Group</span>
            <multiselect
              class="selectExample"
              v-model="selectedTerritories"
              :options="optionTerritories"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              :custom-label="customLabel"
            >
            </multiselect>
          </div>
          <div class="w-1/3 flex flex-col gap-2">
            <span>Sales Order Type</span>
            <multiselect
              class="selectExample"
              v-model="selectedTerritories"
              :options="optionTerritories"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              :custom-label="customLabel"
            >
            </multiselect>
          </div>
        </div>
      </div>

      <!-- Today data -->
      <div class="flex flex-col gap-6 mb-6">
        <div class="text-3xl font-semibold text-black">Today Data</div>
        <div class="flex items-center gap-6">
          <div class="w-3/5 flex flex-col gap-6">
            <PieChart :chartData="chartDataTodayData" />
            <div class="w-full flex justify-center">
              <div class="p-6 border-r-2 border-grey">
                <TodayDataItem
                  :percentage="30"
                  :total="3000000"
                  :total-item="320"
                  title="Total Complete"
                  :is-minus="false"
                />
              </div>
              <div class="p-6 border-r-2 border-grey">
                <TodayDataItem
                  :percentage="0"
                  :total="1000000"
                  :total-item="500"
                  title="Total Rejected"
                  :is-minus="false"
                />
              </div>
              <div class="p-6">
                <TodayDataItem
                  :percentage="20"
                  :total="2000000"
                  :total-item="300"
                  title="Total SO Exception"
                  :is-minus="false"
                />
              </div>
            </div>
          </div>
          <div class="w-2/5 h-full">
            <div class="text-2xl font-semibold mb-6">Data Goal Completion</div>
            <div class="flex flex-col gap-4">
              <DataGoalItem
                title="Need to PI"
                :total="7596"
                :dataCount="6456"
              />
              <DataGoalItem
                title="Need to POD"
                :total="7596"
                :dataCount="7596"
              />
              <DataGoalItem
                title="Need to Bill"
                :total="7596"
                :dataCount="6456"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Agging Passed Summary -->
      <div class="flex flex-col gap-6 mb-6">
        <div class="text-3xl font-semibold text-black">
          Agging Passed Summary (Last Three Month)
        </div>
        <div class="flex items-center gap-6">
          <div class="w-3/5 flex flex-col gap-6">
            <PieChart :chartData="chartDataTodayData" />
            <div class="w-full flex justify-center">
              <div class="p-6 border-r-2 border-grey">
                <TodayDataItem
                  :percentage="30"
                  :total="3000000"
                  :total-item="320"
                  title="Total Complete"
                  :is-minus="false"
                />
              </div>
              <div class="p-6 border-r-2 border-grey">
                <TodayDataItem
                  :percentage="0"
                  :total="1000000"
                  :total-item="500"
                  title="Total Rejected"
                  :is-minus="false"
                />
              </div>
              <div class="p-6">
                <TodayDataItem
                  :percentage="20"
                  :total="2000000"
                  :total-item="300"
                  title="Total SO Exception"
                  :is-minus="false"
                />
              </div>
            </div>
          </div>
          <div class="w-2/5 h-full">
            <div class="text-2xl font-semibold mb-6">Data Goal Completion</div>
            <div class="flex flex-col gap-4">
              <DataGoalItem
                title="Need to PI"
                :total="7596"
                :dataCount="6456"
              />
              <DataGoalItem
                title="Need to POD"
                :total="7596"
                :dataCount="7596"
              />
              <DataGoalItem
                title="Need to Bill"
                :total="7596"
                :dataCount="6456"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import PieChart from '@/components/charts/PieChart.vue';
import DataGoalItem from './partial/DataGoalItem.vue';
import TodayDataItem from './partial/TodayDataItem.vue';
import { mapState, mapActions } from 'vuex/dist/vuex.common.js';

export default {
  components: {
    PieChart,
    DataGoalItem,
    TodayDataItem,
  },
  data() {
    return {
      baseUrl: '/api/report/v1/territories',
      selectedTerritories: [],
      optionTerritories: [],
      territoryIDs: [],
      territoryCodes: [],
      territoryNames: [],
      draw: 0,
      chartDataTodayData: {
        labels: ['Complete', 'Reject', 'SO Exception'],
        datasets: [
          {
            backgroundColor: ['#598edb', '#fb9c44', '#a6a6a6'],
            data: [70, 20, 10],
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions({ getSoStages: 'soStages/getSoStages' }),
    customLabel(val) {
      if (val) {
        return val.code === '-' ? `${val.name}` : `(${val.code}) ${val.name}`;
      }
    },
  },
  mounted() {
    this.getSoStages();
  },
  computed: {
    ...mapState({
      data: (state) => state.soStages.data,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
